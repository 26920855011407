import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { routes } from "./utils/routes";

const middleware = (Component) => {
  return (props) => {
    const { userData } = useSelector((state) => state?.user);
    if (!userData) {
      return <Navigate to={routes.login} />;
    }
    return <Component {...props} />;
  };
};

export default middleware;
