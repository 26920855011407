import React, { useEffect, useRef, useState } from "react";
import "./YourRole.css";
import OrbixNavbar from "../../components/orbixNavbar/OrbixNavbar";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { ConsoleData } from "../../utils/helper-functions";
import { getAnalyticsList, postBusinessRoleList } from "../../hooks/userManagment";
import { ErrorCodes, Lookups } from "../../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGIN_DATA } from "../../utils/constants";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { isNullOrEmptyArray } from "../../utils/text-utils";
import { loginAction } from "../../redux/AppAction";

export default function YourRole() {
  const { userData } = useSelector((state) => state.user);
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    fetchBusinessRoles();
  }, []);

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  function fetchBusinessRoles() {
    setIsLoading(true);
    getAnalyticsList(Lookups.BUSINESS_ROLE)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code === ErrorCodes.success) {
          setList(data.result);
        } else if (data.error_code === ErrorCodes.failed) {
          toast.warn("No record find");
        } else {
          toast.warn("Oops! Some error occured");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Oops! Some error occured");
      });
  }

  function onNext() {
    if (isNullOrEmptyArray(selectedOptions)) navigate(routes.hear_from);
    else {
      const user = JSON.parse(localStorage.getItem(USER_LOGIN_DATA));
      const dataObject = JSON.stringify(
        selectedOptions.map((item) => ({
          user_id: userData?._id || user?._id,
          business_role_id: item?.value,
        }))
      );
      setIsPostLoading(true);
      postBusinessRoleList(dataObject)
        .then(({ data }) => {
          setIsPostLoading(false);
          if (data.error_code === ErrorCodes.success) {
            let newUserData = { ...userData };
            newUserData.is_user_business_role = true;
            dispatch(loginAction(newUserData));
            navigate(routes.hear_from);
          } else {
            toast.warn("Oops! Some error occured");
          }
        })
        .catch((err) => {
          setIsPostLoading(false);
          toast.error("Network Error!");
        });
    }
  }

  const ref = useRef(null);
  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  return (
    <>
      <ToastContainer autoClose={2000} position="bottom-right" hideProgressBar />
      <div className="yourRoleMainContainer">
        <OrbixNavbar />

        <div ref={ref} className="yourRoleMainContainerYourRole">
          <div className="yourRoleSpaceBtwContainer">
            <div className="yourRoleContainer">
              <h1 className="yourRoleHeadingContainer">What is your Role?</h1>
              <p className="yourRoleParaContainer">
                To personalize your experience, please provide us with some information about
                yourself and your objectives.
              </p>
            </div>
            <div className="containerYourRole">
              <div className="options-list">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  list.map((option) => (
                    <div
                      key={option?.value}
                      className={`option ${selectedOptions.includes(option) ? "selected" : ""}`}
                      onClick={() => toggleOption(option)}
                    >
                      <input
                        type="radio"
                        checked={selectedOptions.includes(option)}
                        onChange={() => toggleOption(option)}
                      />
                      <label className="optionLabel">{option?.label}</label>
                    </div>
                  ))
                )}
              </div>
              <div className="button-container">
                <button
                  onClick={() => {
                    navigate(routes.welcome);
                  }}
                  className="buttonBack"
                >
                  Back
                </button>

                <div>
                  {/* <span
                  style={{
                    fontSize: "12px",
                    marginRight: "20px",
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(routes.hear_from);
                  }}
                >
                  Skip this Step
                </span> */}
                  <button onClick={onNext} disabled={isLoading || isPostLoading} className="button">
                    {isPostLoading ? (
                      <LoadingSpinner />
                    ) : isNullOrEmptyArray(selectedOptions) ? (
                      "Skip this step"
                    ) : (
                      "Next Step"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <p className="yourRoleBottomPara">© 2024 Orbix. All Rights Reserved.</p>
        </div>
      </div>
    </>
  );
}
