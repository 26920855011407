import React, { useEffect, useState } from "react";
import "./style.css";
import buttonIcon from "../../assets/send.svg";
import LeftChat from "./LeftChat";
import RightChat from "./RightChat";
import { useLocation, useNavigate } from "react-router-dom";
import { getChatList, getChatNode, sendMessage } from "../../hooks/chat";
import { ErrorCodes } from "../../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import { HEADINGS_LIGHT } from "../../utils/colors";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrEmptyArray } from "../../utils/text-utils";
import {
  chatHeadStatus,
  chatListState,
  selectedChatIdState,
} from "../../redux/AppAction";
import { CATEGORY_ID, USER_LOGIN_DATA } from "../../utils/constants";
import { routes } from "../../utils/routes";
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { LuDot } from "react-icons/lu";
import chatIcon_1 from "../../assets/Group 1171275092.svg";
import chatIcon_2 from "../../assets/Group 1171275110.svg";
import PromptCards from "./PromptCards";
function ChatbotRight_Communication() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { selectedChatId, chatsList, selectedCategoryId } = useSelector(
    (state) => state.chat
  );
  const { chat_head_id } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);

  const { default_prompts } = location?.state || [];

  const [text, setText] = useState("");
  const [chatResponseLoading, setChatResponseLoading] = useState(false);

  const handlePasteFromDiv = (divName) => {
    const divText = document.getElementById(divName).innerText;
    setText(divText);
  };

  useEffect(() => {
    if (chat_head_id) {
      fetchChatList(chat_head_id);
    } else {
      // setData([]);
      dispatch(chatListState([]));
    }
  }, [chat_head_id]);

  // useEffect(() => {
  //   if (!isNullOrEmptyArray(chatsList)) {
  //     setData(chatsList);
  //   } else setData([]);
  // }, [chatsList]);

  function fetchChatList(chat_head_id) {
    setIsLoading(true);
    getChatList(chat_head_id)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code === ErrorCodes.success) {
          let newArray = [];
          for (let index = 0; index < data.result.length; index++) {
            const element = data.result[index];
            let userObject = {
              role: "user",
              content: element.user_content,
              created_on: element.created_on,
            };
            let botObject = {
              role: "assistant",
              content: element.bot_content,
            };
            newArray.push(userObject);
            newArray.push(botObject);
          }
          // setData(newArray);
          dispatch(chatListState(newArray));
        } else if (data.error_code === ErrorCodes.failed) {
          // setData([]);
        } else {
          toast.warn("Oops! Some error occured");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Network Error!");
      });
  }

  const AlwaysScrollToBottom = () => {
    const element = document.getElementById("chat__panel");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const onSend = (e) => {
    e.preventDefault();
    let newArray = [...chatsList];
    newArray.push({ role: "user", content: text });
    dispatch(chatListState(newArray));
    setTimeout(() => {
      AlwaysScrollToBottom();
    }, 500);
    setText("");

    const user = JSON.parse(localStorage.getItem(USER_LOGIN_DATA));
    const catId = localStorage.getItem(CATEGORY_ID);

    let object = {
      title: text,
      category_id:
        selectedCategoryId ||
        catId ||
        (newArray.length > 0 ? newArray[0]?.category_id : null),
      user_id: userData?._id || user?._id,
      chat_head_id: selectedChatId ? selectedChatId : null,
      messages: newArray,
    };

    setChatResponseLoading(true);
    sendMessage(object)
      .then(({ data }) => {
        if (data.error_code == ErrorCodes.success) {
          if (!selectedChatId) {
            dispatch(chatHeadStatus(data.result.chat_head));
          }
          dispatch(selectedChatIdState(data.result.chat_head._id));
          setChatResponseLoading(false);
          let newText = data.result.result;
          newArray.push({ role: "assistant", content: newText });
          dispatch(chatListState(newArray));
          setText("");
          AlwaysScrollToBottom();
        } else if (data.error_code == ErrorCodes.not_exist) {
          alert(
            "Your subscription has been expired, Please update your subscription."
          );
        }
      })
      .catch((err) => {
        setChatResponseLoading(false);
        console.log("err", err);
      });
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };
  const handleNavigate = function () {
    navigate(routes.dashboard);
  };

  return (
    <>
      <ToastContainer
        autoClose={2000}
        position="bottom-right"
        hideProgressBar
      />
      <div className="w-full h-full pb-2 flex flex-col gap-1 pr-5">
        <div className="max-h-[15%] h-auto min-h-[10%] pb-2 ">
          <div className=" text-xl pt-5 font-bold flex items-center gap-2 justify-start">
            <IoCaretBackCircleSharp
              onClick={handleNavigate}
              size={23}
              className="cursor-pointer text-gray-400"
            />
            Dashboard
          </div>
          <div className="flex text-[10px] items-center pb-1 pl-8">
            AI Tools <LuDot className="text-lg mx-2" /> Writing Tools
          </div>
        </div>
        <div
          className="w-full max-h-[84%] h-full min-h-[77%] border rounded-xl border-blue-100   mr-3 "
          id="communication"
        >
          <div
            className="w-full mb-2 h-full rounded-xl no-scrollbar  overflow-y-scroll shadow-xl  bg-white "
            id="chat__panel"
            onLoad={() => {
              AlwaysScrollToBottom();
            }}
          >
            <div className="md:mt-2 px-5 my-2 h-full pt-5">
              {isLoading ? (
                <LoadingSpinner />
              ) : chatsList.length === 0 ? (
                <div className="h-full flex flex-col justify-center pt-5">
                  <div className="w-2/3 my-4 m-auto text-center ">
                    <h1 className="text-lg chatBot_main_heading md:text-2xl font-semibold md:pb-1">
                      Welcome to Orbi powered by Orbix 💬
                    </h1>
                    <p className="lg:px-40 py-1 chatBot_main_heading_text text-gray-400">
                      Get Started by writing a task and Orbix Chat can do the
                      rest. Not sure where to start? Check out the Prompt
                      Library for inspiration.
                    </p>
                  </div>
                  <div className="md:my-6 py-4 w-4/5 grid grid-cols-12 gap-3 justify-center m-auto">
                    {default_prompts?.map((item, index) => {
                      return (
                        <PromptCards
                          title={item.title}
                          message={item.message}
                          key={index}
                          index={index}
                          handlePasteFromDiv={handlePasteFromDiv}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                chatsList.map((item) => (
                  <div className="pt-5">
                    {item.role === "user" ? (
                      <RightChat
                        content={item?.content}
                        time={item?.created_on}
                      />
                    ) : item.role === "assistant" ? (
                      <LeftChat content={item?.content} />
                    ) : null}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="">
          <form className=" flex gap-2 h-full" onSubmit={onSend}>
            <input
              className="main_chatBot_input w-[85%] sm:w-[90%] md:w-[95%] rounded-lg shadow-xl h-full min-h-10"
              type="text"
              placeholder="Ask or Search anything"
              value={text}
              onChange={handleInputChange}
            />
            <button
              onClick={onSend}
              disabled={!text}
              className={`${
                text ? "bg-white" : "bg-slate-200"
              } rounded shadow-xl w-[15%] sm:w-[10%] md:w-[5%] flex justify-center items-center`}
              style={{
                cursor: "pointer",
                border: text ? "1px solid skyblue" : "",
              }}
            >
              {chatResponseLoading ? (
                "......."
              ) : (
                <img className="" src={buttonIcon} alt="" />
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChatbotRight_Communication;
