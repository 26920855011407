import React from "react";
import exploreCategoryImg1 from "../../assets/exploreCategoryImg1.svg";
import OrbiImg from "../../assets/OrbiImg.svg";
import "./ExploreCategory.css";
import { BASE_URL } from "../../utils/constants";

export default function ExploreCategory({
  id,
  image,
  title,
  description,
  bgColor,
  default_prompts,
  onClick = () => {},
}) {
  return (
    <div className="exploreCategoryMainContainer col-span-12 lg:col-span-6">
      <div
        style={{ backgroundColor: bgColor }}
        className="exploreCategoryImgContainer"
      >
        <img src={BASE_URL.concat(image)} alt="" />
      </div>

      <div className="exploreCategoryContainer">
        <h3>{title}</h3>
        <p className="para">{description}</p>
        <button onClick={() => onClick(id,default_prompts)} className="exploreCategoryButton">
          <img src={OrbiImg} alt="" />
          Explore
        </button>
      </div>
    </div>
  );
}
