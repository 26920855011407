import React, { useEffect, useState } from "react";
import "./style.css";
import ChatBot_Navbar from "./ChatBot_Navbar";
import { FaAngleRight } from "react-icons/fa";
import { BsReverseLayoutSidebarReverse } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import { Outlet, useNavigate } from "react-router-dom";
import Category from "./Category.jsx";
import { FaAngleLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGIN_DATA } from "../../utils/constants.js";
import { categoriesDataState, chatHeadStatus, selectedChatIdState } from "../../redux/AppAction.js";
import { ErrorCodes } from "../../utils/enums.js";
import { getAllCategoriesWithChatHeads, getChatNode } from "../../hooks/chat.js";
import LoadingSpinner from "../loading-spinner/LoadingSpinner.jsx";
import { ToastContainer, toast } from "react-toastify";
import { isNullOrEmpty, isNullOrEmptyArray } from "../../utils/text-utils.js";
import OrbixNavbar from "../orbixNavbar/OrbixNavbar.jsx";
import { routes } from "../../utils/routes.js";
import { PRIMARY } from "../../utils/colors.js";
function ChatBotMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { categoriesData, newChatHead, selectedCategoryId } = useSelector((state) => state.chat);
  const [isLoading, setIsLoading] = useState(false);

  console.log("categoriesData", categoriesData);

  useEffect(() => {
    jsCall();
    if (isNullOrEmptyArray(categoriesData) && userData) getCategories(userData?._id);
  }, [userData]);

  // useEffect(() => {
  //   if (userData && selectedCategoryId) {
  //     fetchChatNode();
  //   }
  // }, [userData, selectedCategoryId]);

  // function fetchChatNode() {
  //   setIsLoading(true);
  //   getChatNode(userData._id, selectedCategoryId)
  //     .then(({ data }) => {
  //       setIsLoading(false);
  //       if (data.error_code === ErrorCodes.success) {
  //         dispatch(categoriesDataState(data.result));
  //       } else {
  //         toast.warn("Oops! Some error occured");
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       toast.error("Network Error!");
  //     });
  // }

  // useEffect(() => {
  //   if (!isNullOrEmpty(newChatHead)) {
  //     let categoriesList = [...categoriesData];
  //     var categoryData = categoriesList.find((item) => item._id == newChatHead.category._id);
  //     var chatHead = categoryData?.chat_heads?.findIndex((item) => item._id == newChatHead._id);
  //     if (chatHead === -1) {
  //       categoryData?.chat_heads?.push(newChatHead);
  //       dispatch(categoriesDataState(categoriesList));
  //     }
  //   }
  // }, [newChatHead]);

  useEffect(() => {
    AOS.init();
  }, []);

  function jsCall() {
    const collapseBtn = document.getElementById("collapseButton");
    const collapseBtn_1 = document.getElementById("collapseButton_1");
    const content = document.getElementById("contentButton");

    collapseBtn?.addEventListener(
      "click",
      () => (
        content.classList.remove("sm:block"),
        content.classList.add("sm:hidden"),
        content.classList.remove("block"),
        content.classList.add("hidden"),
        collapseBtn_1.classList.remove("sm:hidden"),
        collapseBtn_1.classList.add("sm:block"),
        collapseBtn_1.classList.remove("hidden"),
        collapseBtn_1.classList.add("block")
      )
    );

    collapseBtn_1.addEventListener(
      "click",
      () => (
        content.classList.remove("sm:hidden"),
        content.classList.add("sm:block"),
        content.classList.remove("hidden"),
        content.classList.add("block"),
        collapseBtn_1.classList.add("sm:hidden"),
        collapseBtn_1.classList.remove("sm:block"),
        collapseBtn_1.classList.remove("block"),
        collapseBtn_1.classList.add("hidden")
      )
    );
  }

  function getCategories(id) {
    setIsLoading(true);
    getAllCategoriesWithChatHeads(id)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code === ErrorCodes.success) {
          dispatch(categoriesDataState(data.result));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Network Error!");
      });
  }

  return (
    <div className="h-screen chatBot_main_background">
      <div className="h-[12%]">
        <OrbixNavbar />
      </div>

      <div className="w-full h-[88%] ">
        <div className="flex md:pl-2 px-2 gap-2 h-full  ">
          <ToastContainer autoClose={2000} position="bottom-right" hideProgressBar />
          <div
            id="contentButton"
            className=" pt-5 pb-2 w-1/4 hidden h-[88%] sm:h-full sm:block "
            data-aos="fade-right"
          >
            <div className=" py-5 px-2 h-full bg-white rounded-xl shadow-xl border border-blue-100">
              <div className="w-11/12 h-full m-auto no-scrollbar overflow-y-scroll">
                <div className="flex w-full justify-between items-center ">
                  <button id="collapseButton" className="text-2xl  text-gray-900  rounded-lg">
                    <BsReverseLayoutSidebarReverse size={20} color={PRIMARY} />
                  </button>
                  <button
                    id="newButton"
                    className="  w-24 text-sm py-2 rounded-3xl bg-blue-500 text-white"
                    onClick={() => {
                      dispatch(selectedChatIdState(null));
                      dispatch(chatHeadStatus(null));
                      navigate(routes.dashboard);
                    }}
                  >
                    New Chat
                  </button>
                </div>
                <div className="py-5 overflow-y-scroll no-scrollbar ">
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : categoriesData.length === 0 ? (
                    <p style={{ textAlign: "center" }}>Oops! some error occured</p>
                  ) : (
                    categoriesData
                      .filter((x) => x._id == selectedCategoryId)
                      .map((item) => (
                        <>
                          <Category
                            catTitle={item?.name}
                            catId={item._id}
                            chatHeadList={item?.chat_heads}
                          />
                          {/* <Category
                          catTitle={item?.category?.name}
                          catId={item.category?._id}
                          chatHeadList={item?.chat_heads}
                        /> */}
                        </>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            id="collapseButton_1"
            className=" mb-auto mt-11 block sm:hidden text-2xl  text-blue-500 rounded-lg"
          >
            {" "}
            <BsReverseLayoutSidebarReverse size={22} className="text-sm md:text-2xl" />
          </button>
          <div className="w-full h-full">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBotMain;
