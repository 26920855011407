import React, { useEffect, useRef } from "react";
import SecondWelcome_Sidebar from "./SecondWelcome_Sidebar";
import OrbixNavbar from "../../components/orbixNavbar/OrbixNavbar";

function SecondWelcome_Setting() {
  const ref = useRef(null);
  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);
  return (
    <div
      ref={ref}
      className="col-span-12 w-screen flex justify-center items-center"
    >
      <div className="w-11/12 pt-14 pl-14">
        <div className="w-full h-auto xxs:px-7 py-3">
          <div className="shadow-xl bg-white w-full min-h-[85vh] h-auto rounded-2xl flex flex-col items-center">
            <div className="w-full p-6">
              <div className="w-full mb-2">
                <h1 className="md:text-xl font-bold">Setting</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondWelcome_Setting;
