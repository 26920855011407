import React, { useEffect, useRef, useState } from "react";
import "./signup.css";
import logo from "../../assets/logo.svg";
import signUpHalfImg from "../../assets/signUpHalfImg.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ErrorCodes } from "../../utils/enums";
import { signup } from "../../hooks/userManagment";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/AppAction";
import { USER_LOGIN_DATA } from "../../utils/constants";
import { routes } from "../../utils/routes";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";


const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSecure, setIsSecure] = useState(false);
  const [isSecureConfirm, setIsSecureConfirm] = useState(false);

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) setPassErr(true);
    else {
      let bodyFormData = new FormData();
      bodyFormData.append("name", name);
      bodyFormData.append("email", email);
      bodyFormData.append("password", password);
      setIsLoading(true);
      signup(bodyFormData)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code === ErrorCodes.success) {
            dispatch(loginAction(data.result));
            localStorage.setItem(USER_LOGIN_DATA, JSON.stringify(data.result));
            // navigate(routes.welcome);
            navigate(routes.otp);
          } else {
            toast.warn(data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Network Error!");
        });
    }
  };
  const ref = useRef(null);
  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} hideProgressBar={true} position="bottom-right" />
      <div ref={ref} className="signup-container flex flex-col md:flex-row relative ">
      <div
        className="cursor-pointer absolute left-5 top-5"
        onClick={()=>navigate('/')}
        >
       <IoMdArrowRoundBack   className="text-3xl text-white"/>
        </div>
        <div class="left-section w-full md:w-1/2 ">
          <div class="top-div">
            <img
              style={{ objectFit: "contain", width: "100%" }}
              src={signUpHalfImg}
              alt="Robot Image"
              class="robot-image"
            />
          </div>
          <div class="bottom-div loginWidth ">
            <img src={logo} alt="Orbix Logo" class="logo" />
            <h1 className="text-2xl  md:text-3xl">Your Artificial Intelligent Bot, Orbix</h1>
            <p class="text">
              Our Intelligent Conversational AI solution is designed to revolutionize the way
              insurance companies engage.
            </p>
          </div>
        </div>

        <div className="signup-right w-full md:w-1/2 ">
          <form className="signup-form" onSubmit={handleSubmit}>
            <h2>Create account</h2>
            <p>Fill information to create account</p>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your Email"
                required
              />
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <label htmlFor="password">Password</label>
              <input
                type={isSecure ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => {
                  setPassErr(false);
                  handlePasswordChange(e);
                }}
                placeholder="Enter your Password"
                required
                style={{
                  border: passErr ? "1px solid red" : "",
                  position: "relative",
                }}
              />
              <span className="eye_icon" onClick={() => setIsSecure(!isSecure)}>
                {isSecure ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
              </span>
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type={isSecureConfirm ? "text" : "password"}
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                  setPassErr(false);
                  handleConfirmPasswordChange(e);
                }}
                placeholder="Enter your Password"
                required
                style={{
                  border: passErr ? "1px solid red" : "",
                  position: "relative",
                }}
              />
              {passErr && (
                <div className="new_pass_input_error">Confirm Password doesn’t matched*</div>
              )}

              <span className="eye_icon" onClick={() => setIsSecureConfirm(!isSecureConfirm)}>
                {isSecureConfirm ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
              </span>
            </div>
            <button type="submit" className="btn-signup" style={{ marginTop: "35px" }}>
              {isLoading ? <LoadingSpinner /> : "Create Account"}
            </button>
          </form>
          <p className="login_signup_text">
            Already have an account?
            <span onClick={() => navigate(routes.login)}>Login</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUp;
