import React, { useEffect } from "react";
import Prompts from "./home/Prompts";

export default function FeatureScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mt-16 nd:mt-0">
      {/* <h1 className="mt-20">Features Screen</h1> */}

      <Prompts />
    </div>
  );
}
