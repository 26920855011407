import React from 'react'

export default function Home({fill ='#23539C'}) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5698 2.9667L3.63978 8.5167C2.85978 9.1367 2.35978 10.4467 2.52978 11.4267L3.85978 19.3867C4.09978 20.8067 5.45978 21.9567 6.89978 21.9567H18.0998C19.5298 21.9567 20.8998 20.7967 21.1398 19.3867L22.4698 11.4267C22.6298 10.4467 22.1298 9.1367 21.3598 8.5167L14.4298 2.9767C13.3598 2.1167 11.6298 2.1167 10.5698 2.9667Z" fill={fill} stroke="#23539C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5 18.1467V15.1467" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
