import "./referral.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../utils/routes";
import Loader from "../../components/loading-spinner/Loader";
import { FaCheckCircle } from "react-icons/fa";
import { getReferralDiscountDetailCall } from "../../hooks/plan";

const features2 = [
  "Full Access to Orbi",
  "Personalized Sales Mentor",
  "Detailed Product Insights",
  "Automated Underwriting",
  "Carrier Selection Assistance",
  "Compliance Support",
];

const features3 = [
  "Full Access to Orbi",
  "Team Sales Training",
  "All features of the Starter plan",
  "Priority 24/7 support",
];

const features5 = [
  "Earn 15% all feature (Agent) commission for each referral that subscribes through your affiliate link. Plus a 21% discount on your monthly subscription.",
];

const ReferralPage = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    activePlan: null,
    activeBillingAddress: null,
    activePaymentMethod: null,
    storedPaymentMethods: [],
    storedBillingAddresses: [],
    stripeInvoices: [],
    selectedCardEdit: null,
    referralCode: null,
    referralDiscountData: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!location.search) {
      navigate(-1);
    } else {
      let code = location.search.split("=")[1];
      setState((prevState) => ({ ...prevState, referralCode: code }));
    }
  }, [location]);

  useEffect(() => {
    if (state.referralCode) getReferralDiscountData();
  }, [state.referralCode]);

  function getReferralDiscountData() {
    setIsLoading(true);
    getReferralDiscountDetailCall(state.referralCode)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          setState((prevState) => ({ ...prevState, referralDiscountData: data.result }));
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  return (
    <div ref={ref} className="col-span-12 w-screen flex justify-center items-center">
      <div className="w-11/12 pt-14 pl-14">
        <div className="w-full h-auto xxs:px-7 py-3">
          <div className="shadow-xl bg-white w-full min-h-[85vh] h-auto rounded-2xl flex flex-col items-center">
            <div className="w-full p-3 md:p-6">
              <div className="flex md:flex-row flex-col gap-2">
                <div className="w-full ">
                  <div className="text-[12px] sm:text-base font-semibold pb-5">
                    Select a hosting plan
                  </div>

                  <div className="flex flex-row gap-2">
                    <PaymentPlanCard
                      align
                      // tag
                      // tagline="72 hour trial"
                      plan="Affiliate"
                      price="999.00"
                      duration="OneTime Payment"
                      features={features5}
                      isHome
                      disabled={isLoading}
                      bottomViewAffiliate
                      referralDiscount={state.referralDiscountData?.referral_discount}
                      refereeDiscount={state.referralDiscountData?.referee_discount}
                      onClickGetStarted={() => {
                        if (state.referralCode) {
                          let object = {
                            plan_name: "Affiliate",
                            tag_line: "OneTime Payment",
                            features: features5,
                            plan_id: "667d0612ca6657bebdd86f2b",
                            plan_price: 999.0,
                            referralCode: state.referralCode,
                            referralDiscountData: state.referralDiscountData,
                          };
                          navigate(routes.referral_plan, { state: object });
                        }
                      }}
                    />

                    <PaymentPlanCard
                      plan="Agency"
                      price="499.00"
                      duration="Per Month/10 Users"
                      features={features3}
                      isHome
                      disabled={isLoading}
                      bottomViewAffiliate
                      referralDiscount={state.referralDiscountData?.referral_discount}
                      refereeDiscount={state.referralDiscountData?.referee_discount}
                      onClickGetStarted={() => {
                        if (state.referralCode) {
                          let object = {
                            plan_name: "Agency",
                            tag_line: "Per Month/10 Users",
                            features: features3,
                            plan_id: "6659b1cf9bbb09e0e72c5f82",
                            plan_price: 499.0,
                            referralCode: state.referralCode,
                            referralDiscountData: state.referralDiscountData,
                          };
                          navigate(routes.referral_plan, { state: object });
                        }
                      }}
                    />

                    <PaymentPlanCard
                      tag
                      tagline="Perfect for Personal use "
                      plan="Agent"
                      price="49.00"
                      duration="Per Month"
                      features={features2}
                      isHome
                      disabled={isLoading}
                      bottomViewAffiliate
                      referralDiscount={state.referralDiscountData?.referral_discount}
                      refereeDiscount={state.referralDiscountData?.referee_discount}
                      onClickGetStarted={() => {
                        if (state.referralCode) {
                          let object = {
                            plan_name: "Agent",
                            tag_line: "Perfect for Personal use (Per Month)",
                            features: features2,
                            plan_id: "667908fa74e357b6063d3b6f",
                            plan_price: 49.0,
                            referralCode: state.referralCode,
                            referralDiscountData: state.referralDiscountData,
                          };
                          navigate(routes.referral_plan, { state: object });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <Loader /> : null}
    </div>
  );
};

function PaymentPlanCard({
  tagline,
  plan,
  price,
  duration,
  features,
  tag,
  amountText,
  cardBtn = "Choose Plan",
  align,
  isHome = false,
  disabled = false,
  onClickGetStarted,
  referralDiscount,
  refereeDiscount,
}) {
  const navigate = useNavigate("");

  function getAmountAfterDiscount() {
    let discountedPrice =
      (parseInt(price) / 100) * (refereeDiscount ? parseFloat(refereeDiscount) : 21);
    discountedPrice = price - discountedPrice;
    return discountedPrice.toFixed(2);
  }

  return (
    <>
      <div className="pricing-card-container">
        {tag && <div className="tagline">{tagline}</div>}
        <h2 className="plan">{plan}</h2>
        <div className="price">
          <span className="amount">${getAmountAfterDiscount()}</span>
          <p className="amount line-through font-semibold text-slate-400 text-xl">
            {price ? `$${price}` : ""}
          </p>
          <span className="amountText">{amountText}</span>
          <p className="duration">{duration}</p>
        </div>
        <ul className="features">
          {features.map((feature, index) => (
            <div style={{ alignItems: align ? "start" : "center" }} className="ulContainer">
              <FaCheckCircle
                className="checkIcon"
                size={align ? "60" : ""}
                style={{ marginLeft: align ? "20px" : "" }}
              />{" "}
              <li className="lists" key={index}>
                {feature}
              </li>
            </div>
          ))}
        </ul>

        <button
          className="get-started"
          disabled={disabled}
          onClick={() => {
            if (!isHome) navigate(routes.dashboard);
            else if (price) {
              onClickGetStarted();
            }
          }}
        >
          {cardBtn}
        </button>
      </div>
    </>
  );
}

export default ReferralPage;
