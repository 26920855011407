import React, { useEffect, useRef, useState } from "react";
import basic from "../../assets/billing_basic.png";
import medium from "../../assets/billing_meduim.png";
import premium from "../../assets/billing_premium.png";
import billing_master from "../../assets/billing_master.png";
import billing_visa from "../../assets/billing_visa.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDescriptionDetailCall,
  planAttachPaymentMethodCall,
  planEditPaymentMethodCall,
  planMakePaymentMethodDefaultCall,
  planUnSubscribeCall,
} from "../../hooks/plan";
import Loader from "../../components/loading-spinner/Loader";
import moment from "moment/moment";
import "./SecondWelcome_Billing.css";
import {
  useElements,
  useStripe,
  CardElement,
  Elements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { IoCloseCircle } from "react-icons/io5";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { Chip } from "../../assets";
import { STRIPE_PUBLISH_KEY, USER_LOGIN_DATA } from "../../utils/constants";
import { logout } from "../../redux/AppAction";

function SecondWelcome_Billing() {
  const { userData } = useSelector((state) => state?.user);
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

  const [state, setState] = useState({
    activePlan: null,
    activeBillingAddress: null,
    activePaymentMethod: null,
    storedPaymentMethods: [],
    storedBillingAddresses: [],
    stripeInvoices: [],
    selectedCardEdit: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [addNewCardOpen, setAddNewCardOpen] = useState(false);

  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (userData) getBillingDetails();
  }, [userData]);

  function getBillingDetails() {
    setIsLoading(true);
    getUserDescriptionDetailCall(userData._id)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          setState((prevState) => ({
            ...prevState,
            activePlan: data.result.active_plan,
            activeBillingAddress: data.result.active_billing_address,
            activePaymentMethod: data.result.active_payment_method,
            storedPaymentMethods: data.result.stored_payment_methods,
            storedBillingAddresses: data.result.stored_billing_addresses,
            stripeInvoices: data.result.stripe_invoices,
            selectedCardEdit: null,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            activePlan: null,
            activeBillingAddress: null,
            activePaymentMethod: null,
            storedPaymentMethods: [],
            storedBillingAddresses: [],
            stripeInvoices: [],
            selectedCardEdit: null,
          }));
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  function attachPaymentMethod(stripe_payment_method_id) {
    let object = {
      stripe_payment_method_id: stripe_payment_method_id,
      user_email: userData.email,
    };
    setIsLoading(true);
    planAttachPaymentMethodCall(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          getBillingDetails();
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  function editStripeCardDetails(value) {
    let object = {
      stripe_id: state.selectedCardEdit.stripe_id,
      name: value.name,
      email: userData.email,
      phone: value.phone,
      city: value.address.city,
      country: value.address.country,
      line1: value.address.line1,
      line2: value.address.line2,
      postal_code: value.address.postal_code,
      state: value.address.state ? value.address.state : null,
    };
    setIsLoading(true);
    planEditPaymentMethodCall(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) getBillingDetails();
        else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  function makeCardDefault(item) {
    let object = {
      stripe_payment_method_id: item.stripe_id,
      user_email: userData.email,
    };
    setIsLoading(true);
    planMakePaymentMethodDefaultCall(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          for (let index = 0; index < state.storedPaymentMethods.length; index++) {
            const card = state.storedPaymentMethods[index];
            if (card.stripe_id == item.stripe_id) card.is_default = true;
            else card.is_default = false;
          }
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  function onHandleUnSubscribe() {
    let object = {
      _id: state.activePlan._id,
    };
    console.log("state.activePlan", state.activePlan);
    setIsLoading(true);
    planUnSubscribeCall(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          alert("Your plan is unsubribed successfully. Now please login again to continue.");
          localStorage.removeItem(USER_LOGIN_DATA);
          dispatch(logout());
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  function CardComponent({ card, index }) {
    const [isCardMenuOpen, setIsCardMenuOpen] = useState(false);
    return (
      <div
        key={index}
        className={`col-span-12 lg:col-span-6 flex flex-col md:mt-5 px-4 md:px-3 py-2 rounded-xl  bg-gradient-to-br from-blue-300 via-blue-600 to-blue-900 relative`}
      >
        <div className="w-full py-3 flex justify-between items-center">
          <div className="text-white uppercase text-sm lg:text-xl">{card.brand}</div>
          <div className="flex flex-row gap-1 items-center">
            {card.is_default && (
              <div className="text-sm lg:text-base bg-gradient-to-t from-amber-200 to-amber-400 inline-block text-transparent bg-clip-text">
                Default
              </div>
            )}
            <BiDotsVerticalRounded
              className="cursor-pointer"
              color="#ffffff"
              onClick={(e) => {
                setIsCardMenuOpen(!isCardMenuOpen);
              }}
            />
          </div>

          <div
            className={`absolute ${
              isCardMenuOpen ? "flex" : "hidden"
            } top-10 right-8 bg-white justify-center rounded-md flex-col p-2 gap-2 min-w-24`}
          >
            <div
              className="hover:bg-blue-700 hover:text-white rounded-md text-base text-black px-2 flex cursor-pointer "
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectedCardEdit: card,
                }));
                setAddNewCardOpen(true);
              }}
            >
              Edit
            </div>
            {!card.is_default && (
              <div
                className="hover:bg-blue-700 hover:text-white rounded-md text-base text-black px-2 flex cursor-pointer"
                onClick={() => {
                  makeCardDefault(card);
                }}
              >
                Default
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <img src={Chip} className="w-[35px] xxs:w-[50px] xxs:h-[50px]" />
          <div className="w-2/3 flex justify-end">
            <div className="text-[10px] xxs:text-sm text-white text-center max-w-fit">
              {card?.line1}
            </div>
            <div className="text-[10px] xxs:text-sm text-white text-center max-w-fit">
              {card.phone}
            </div>
          </div>
        </div>

        <div className=" grid grid-cols-12 mt-2 w-[90%]">
          <div className=" xxs:col-span-3 col-span-6 lg:col-span-3 text-xl nd:text-2xl lg:text-xl 2xl:text-4xl bg-gradient-to-t from-amber-200 to-amber-400 inline-block text-transparent bg-clip-text">
            ****
          </div>
          <div className=" xxs:col-span-3 col-span-6 lg:col-span-3 text-xl nd:text-2xl lg:text-xl 2xl:text-4xl bg-gradient-to-t from-amber-200 to-amber-400 inline-block text-transparent bg-clip-text">
            ****
          </div>
          <div className=" xxs:col-span-3 col-span-6 lg:col-span-3 text-xl nd:text-2xl lg:text-xl 2xl:text-4xl bg-gradient-to-t from-amber-200  to-amber-400 inline-block text-transparent bg-clip-text">
            ****
          </div>
          <div className=" xxs:col-span-3 col-span-6 lg:col-span-3 text-xl nd:text-2xl lg:text-xl 2xl:text-4xl bg-gradient-to-t from-amber-200 to-amber-400 inline-block text-transparent bg-clip-text">
            {card.last_digits}
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <div className="flex mt-2 text-[10px] xxs:text-sm  items-center gap-1 text-white font-semibold">
            {card?.name}
          </div>
          <div className="flex mt-2  text-[10px] xxs:text-sm items-center gap-1 text-white">{`${card?.exp_month}/${card?.exp_year}`}</div>
        </div>
      </div>
    );
  }

  return (
    <div ref={ref} className="col-span-12 w-screen flex justify-center items-center">
      <div className="w-11/12 pt-14 pl-14">
        <div className="w-full h-auto xxs:px-7 py-3">
          <div className="shadow-xl bg-white w-full min-h-[85vh] h-auto rounded-2xl flex flex-col items-center">
            <div className="w-full p-3 md:p-6">
              <div className="w-full mb-2">
                <h1 className="md:text-xl font-bold">Billing Detail</h1>
                <p className="text-[10px] sm:text-xs">Please set your billing details</p>
              </div>
              <div className="flex md:flex-row flex-col gap-2">
                <div className="md:w-3/5 ">
                  <div className="p-5 rounded-lg shadow-lg">
                    <div className="text-[12px] sm:text-base font-semibold pb-5">Plan</div>
                    <div className="flex gap-1 sm:gap-2">
                      {[
                        {
                          image: basic,
                          label: "Agent",
                          price: "49.00",
                          period: "/mo",
                          plan_id: "667908fa74e357b6063d3b6f",
                        },
                        {
                          image: medium,
                          label: "Agency",
                          price: "499.00",
                          period: "/mo",
                          plan_id: "6659b1cf9bbb09e0e72c5f82",
                        },
                        {
                          image: premium,
                          label: "Affiliate",
                          price: "999.00",
                          period: "/mo",
                          plan_id: "667d0612ca6657bebdd86f2b",
                        },
                      ].map((plan, index) => (
                        <div
                          key={index}
                          className={`w-[31%] flex flex-col justify-center border md:mt-5 px-1 sm:px-4 py-2 rounded-md ${
                            state.activePlan?.plan_id == plan.plan_id &&
                            "bg-blue-50 border-2 border-blue-300"
                          } `}
                        >
                          <div className="py-3">
                            <img className="w-3 sm:w-8" src={plan.image} alt={plan.label} />
                          </div>
                          <div className="text-[6px] sm:text-sm">{plan.label}</div>
                          <div className="flex">
                            <div className="text-[7px] xxs:text-[8px] sm:text-xl md:text-[15px] font-bold">
                              {plan.price}
                            </div>
                            {plan.period && <p className="text-[6px] sm:text-sm">{plan.period}</p>}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="w-full py-5">
                      {[
                        // { label: "Plan", value: "-" },
                        { label: "Billing name", value: state.activePaymentMethod?.name },
                        {
                          label: "Billing address",
                          value: state.activePaymentMethod?.line1,
                        },
                        {
                          label: "Billing phone number",
                          value: state.activePaymentMethod?.phone,
                        },
                        {
                          label: "Payment method",
                          value: state.activePaymentMethod?.last_digits
                            ? `**** **** **** ${state.activePaymentMethod?.last_digits}`
                            : "",
                        },
                      ].map((item, index) => (
                        <div key={index} className="flex gap-3">
                          <p className="w-1/3 text-[8px] sm:text-[11px] 2xl:text-base text-[#637381] pb-2">
                            {item.label}
                          </p>
                          <p className="w-2/3 text-[8px] sm:text-[11px] 2xl:text-base text-[#637381] pb-2">
                            {item.value}
                          </p>
                        </div>
                      ))}
                    </div>
                    <hr />
                    <div className="w-full flex gap-3 justify-center sm:justify-end pt-5">
                      {/* <button className="bg-gray-200 text-black py-1 sm:py-2 hover:bg-gray-300 focus:outline-none font-medium rounded-full text-[8px] md:text-sm inline-flex items-center px-2 2xl:text-lg text-center">
                        Current Plan
                      </button> */}
                      {state.activePlan ? (
                        <button
                          className="text-white bg-blue-500 py-1 sm:py-2 hover:bg-blue-700 focus:outline-none font-medium rounded-full text-[8px] md:text-sm inline-flex items-center px-2 2xl:text-lg text-center"
                          onClick={() => onHandleUnSubscribe()}
                        >
                          UnSubscribe Plan
                        </button>
                      ) : null}

                      <button
                        className="text-white bg-blue-500 py-1 sm:py-2 hover:bg-blue-700 focus:outline-none font-medium rounded-full text-[8px] md:text-sm inline-flex items-center px-2 2xl:text-lg text-center"
                        onClick={() => {
                          navigate(routes.dashboard_plans);
                        }}
                      >
                        {state.activePaymentMethod?.last_digits ? "Upgrade Plan" : "Buy Plan"}
                      </button>
                    </div>
                  </div>
                  {/* //////////////////////Payment /////////////////////// */}
                  <div className="w-full p-5 rounded-lg shadow-lg">
                    <div className="w-full flex justify-between items-center pb-5">
                      <div className="text-[9px] sm:text-base font-semibold">Payment Method</div>
                      <div
                        className="text-blue-500 text-[6px] sm:text-xs cursor-pointer"
                        onClick={() => {
                          setState((prevState) => ({ ...prevState, selectedCardEdit: null }));
                          setAddNewCardOpen(true);
                        }}
                      >
                        + New Card
                      </div>
                    </div>
                    <div className="grid grid-cols-12 w-full gap-2">
                      {state.storedPaymentMethods.map((card, index) => (
                        <CardComponent card={card} index={index} />
                      ))}
                    </div>
                  </div>
                  {/* //////////////////////Address /////////////////////// */}
                  {/* <div className="w-full p-5 rounded-lg shadow-lg">
                    <div className="w-full flex justify-between items-center pb-5">
                      <div className="text-[9px] sm:text-base font-semibold">
                        Address
                      </div>
                      <div className="text-blue-500 text-[6px] sm:text-xs cursor-pointer">
                        + Address
                      </div>
                    </div>
                    <div className="w-full gap-2">
                      {state.storedBillingAddresses.map((address, index) => (
                        <div
                          key={index}
                          className="w-full flex mb-2 flex-col justify-center border md:mt-5 px-2 sm:px-5 py-2 rounded-md"
                        >
                          <div className="pt-3 pb-1 flex justify-between">
                            <h1 className="flex items-center gap-1 text-[8px] sm:text-[11px] 2xl:text-base font-semibold">
                              {address.title}{" "}
                            </h1>
                          </div>
                          <div className="text-[6px] sm:text-[11px] 2xl:text-base text-[#637381] ">
                            {address.detail}
                          </div>
                          <div className="text-[6px] sm:text-[11px] 2xl:text-base text-[#637381] ">
                            {address.phone_number}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>
                <div className="md:w-2/5 h-auto ">
                  <div className="h-auto rounded-lg shadow-lg p-5">
                    <div className="w-full text-lg black font-semibold mb-5">Invoice History</div>
                    <div className="w-full">
                      {state.stripeInvoices.map((invoice, index) => (
                        <div key={index} className="flex justify-between text-xs text-black py-2 ">
                          <div>{moment(invoice.created).format("DD MMM, YYYY")}</div>
                          <div>${invoice.total}</div>
                          <a
                            href={invoice.invoice_pdf}
                            target="_blank"
                            className="cursor-pointer text-blue-500"
                          >
                            PDF
                          </a>
                        </div>
                      ))}
                      {/* {!showAllInvoices ? (
                        <div
                          className="text-blue-500 text-xs pt-5 cursor-pointer"
                          onClick={() => setShowAllInvoices(true)}
                        >
                          All Invoices
                        </div>
                      ) : (
                        <div
                          className="text-blue-500 text-xs pt-5 cursor-pointer"
                          onClick={() => setShowAllInvoices(false)}
                        >
                          Close Invoices
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {addNewCardOpen && (
        <div className="card__main__container">
          <div className="card_container">
            <div className="sub_card_container">
              <Elements
                stripe={stripePromise}
                // options={{ clientSecret: clientSecretData.client_secret }}
                options={{
                  appearance: {
                    theme: "stripe",
                  },
                }}
              >
                <CreateCard
                  selectedCardEdit={state.selectedCardEdit}
                  callBack={(stripe_payment_method_id) => {
                    setAddNewCardOpen(false);
                    attachPaymentMethod(stripe_payment_method_id);
                  }}
                  callBackEditCardDetails={(value) => {
                    setAddNewCardOpen(false);
                    editStripeCardDetails(value);
                  }}
                  onClose={() => {
                    setAddNewCardOpen(false);
                    setState((prevState) => ({ ...prevState, selectedCardEdit: null }));
                  }}
                />
              </Elements>
            </div>
          </div>
        </div>
      )}
      {isLoading ? <Loader /> : null}
    </div>
  );
}

function CreateCard({
  selectedCardEdit,
  callBack = () => {},
  callBackEditCardDetails = () => {},
  onClose = () => {},
}) {
  const { userData } = useSelector((state) => state?.user);

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!stripe || !elements) return;

    let addressElement = elements.getElement(AddressElement);
    const { complete, value } = await addressElement.getValue();
    if (complete) {
      if (!selectedCardEdit) {
        setIsLoading(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: value.name,
            address: value.address,
            email: userData.email,
            phone: userData.phone,
          },
        });
        console.log("error", error);
        if (error) {
          setMessage(error?.message);
          setIsLoading(false);
        } else if (paymentMethod) {
          setIsLoading(false);
          callBack(paymentMethod.id);
        } else {
          setIsLoading(false);
          setMessage("unexpected state.");
        }
      } else {
        callBackEditCardDetails(value);
      }
    }
  }
  return (
    <form className="flex flex-col p-5 relative" onSubmit={handleSubmit}>
      <IoCloseCircle
        size={27}
        style={{ position: "absolute", top: "-15px", right: "-15px", cursor: "pointer" }}
        onClick={onClose}
      />
      {!selectedCardEdit ? (
        <>
          <CardElement
            options={{
              hidePostalCode: true,
              disableLink: true,
              style: { base: { fontSize: "30px" } },
            }}
          />
          <div
            style={{
              borderWidth: 1,
              flex: 1,
              borderColor: "lightgray",
              margin: "10px 0px",
            }}
          />
        </>
      ) : null}
      <AddressElement
        options={{
          mode: "billing",
          defaultValues: {
            name: selectedCardEdit?.name,
            // phone: selectedCardEdit?.phone,
            address: {
              line1: selectedCardEdit?.line1,
              line2: selectedCardEdit?.line2,
              city: selectedCardEdit?.city,
              state: selectedCardEdit?.state,
              postal_code: selectedCardEdit?.postal_code,
              country: selectedCardEdit?.country,
            },
          },
        }}
      />
      <button type="submit" className="pay_btn">
        {isLoading ? <LoadingSpinner /> : "Add Card"}
      </button>
      {message && <span className=" text-red-500 font-medium text-sm my-3">{message}</span>}
    </form>
  );
}

export default SecondWelcome_Billing;
