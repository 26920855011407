import React from "react";
import "./footer.css";
import { FaXTwitter } from "react-icons/fa6";
import {
  logo,
  Facebook,
  Instagram,
  Github,
  LinkedIn,
} from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";

function Footer() {
  const navigate = useNavigate();

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
const location= useLocation()
  return (
    <div className="footer" id="about">
      <div className="Footer1">
        <div className="Footer_Text">
          <div className="orbix_logo">
            <img src={logo} alt="Orbix Logo" />
          </div>
          <div className="footer_text_detail">
            Create an account, become lightyears ahead of the curve, and
            effortlessly turn every minute into days of work.
          </div>
          <div className="footer_list">
            <div
              onClick={() => location.pathname === '/' ? scrollToElement("features") : navigate('/')}
              className="footer_list_line"
            >
              Product
            </div>
            <div
              onClick={() => navigate(routes.plans)}
              className="footer_list_line"
            >
              Pricing
            </div>
            <div
              onClick={() => navigate(routes.help)}
              className="footer_list_line"
            >
              Help
            </div>
            <div
              onClick={() => navigate(routes.privacy_policy)}
              className="footer_list_line"
            >
              Privacy
            </div>
          </div>
        </div>
      </div>
      <div className="footer2">
        <div className="Footer_Text all_right_orbix">
          © 2023 Orbix. All rights reserved.
        </div>
        <div className="Footer_icon flex gap-5">
          <a href="https://facebook.com/100089883521987">
            <img src={Facebook} alt="Facebook Icon" />
          </a>
          <a href="http://x.com/TheOrbix">
            <FaXTwitter />
          </a>
          <a href="http://www.instagram.com/theorbix">
            <img src={Instagram} alt="Instagram Icon" />
          </a>
          <a href="http://github.com/theorbix">
            <img src={Github} alt="Github Icon" />
          </a>
          <a href="http://linkedin.com/in/theorbix">
            <img src={LinkedIn} alt="LinkedIn Icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
