import React, { useEffect, useRef } from "react";
import "./meetOrbi.css";
import { underline, underline_2, hisign, computer_rebort } from "../../assets";
import AOS from "aos";
import "aos/dist/aos.css";

function Meetorbi() {
  AOS.init();
  return (
    <div className="meet_orbix" id="about">
      <div className="meet_orbix_header_part">
        <h1
          className="meet_orbix_header_text"
        // data-aos="fade-right"
        // data-aos-duration="1000"
        // data-aos-easing="ease-in-out"
        >
          Meet Orbi
        </h1>
        <div className="header_image_under_line">
          <img src={underline} alt="img" />
        </div>
        <div className="meet_orbix_header_text_2">
          A master of all, joker of none.
        </div>
      </div>
      <div className="meet_orbix_uper_part">
        <div
          className="meet_orbix_uper_text_part"
        // data-aos="fade-right"
        // data-aos-duration="1000"
        // data-aos-easing="ease-in-out"
        >
          <div className="artifical_text_1">Your Artificial</div>
          <div className="artifical_text_2">Intelligent Bot, Orbi</div>
          <div className="artifical_detail_text">
            Orbi is a comprehensive resource for life insurance and annuity
            companies, serving as a sales mentor, coach, encyclopedia, and
            underwriter. It provides immediate answers and guidance on products
            and carriers. By leveraging Orbi, you can expedite your application
            process and bid farewell to countless hours of frustration and
            uncertainty.
          </div>
        </div>
        <div className="hi_sign">
          <img src={hisign} alt="img" />
        </div>
      </div>
      <div className="meet_orbix_uper_part meet_orbix_uper_part_2">
        <div className="computer_rebort">
          <img src={computer_rebort} alt="img" />
        </div>
        <div
          className="meet_orbix_uper_text_part"
        // data-aos="fade-left"
        // data-aos-duration="1000"
        // data-aos-easing="ease-in-out"
        >
          <div className="artifical_text_2">
            Leveraging Tech: Orbis Integration Features
          </div>
          <div className="header_image_under_line_2">
            <img src={underline_2} alt="img" />
          </div>
          <div className="artifical_detail_text_2">
            Boost your insurance operations with Orbix's integration
            capabilities. Our smart bot connects with your current systems to
            improve efficiency and workflow. Use Orbi to modernize and simplify
            your insurance ecosystem.
          </div>
          <div className="arrow_blox">
            <h3 className="arror">
              &#x2714; <span className="arror_text">Chatbot API</span>
            </h3>
            <h3 className="arror">
              &#x2714; <span className="arror_text">Streamline Workflow</span>
            </h3>
            <h3 className="arror">
              &#x2714; <span className="arror_text">Improved Efficiency</span>
            </h3>
            <h3 className="arror">
              &#x2714;{" "}
              <span className="arror_text">Modernize Insurance Processes</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meetorbi;
