import React, { useEffect, useState } from "react";
import "./style.css";
import chatIcon_1 from "../../assets/Group 1171275092.svg";
import chatIcon_2 from "../../assets/Group 1171275110.svg";
import buttonIcon from "../../assets/send.svg";
import "aos/dist/aos.css";
import { json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChatList, sendMessage } from "../../hooks/chat";
import { chatHeadStatus, chatListState, selectedChatIdState } from "../../redux/AppAction";
import { CATEGORY_ID, USER_LOGIN_DATA } from "../../utils/constants";
import { ErrorCodes } from "../../utils/enums";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import RightChat from "./RightChat";
import LeftChat from "./LeftChat";
import ChatbotRight_Communication from "./ChatbotRight_Comunication";
import { routes } from "../../utils/routes";

function ChatbotRight_Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedChatId, chatsList, selectedCategoryId } = useSelector((state) => state.chat);
  const { userData } = useSelector((state) => state.user);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchChatList();
  }, [selectedChatId]);

  function fetchChatList(selectedChatId) {
    getChatList(selectedChatId)
      .then(({ data }) => {
        if (data.error_code === ErrorCodes.success) {
          let newArray = [];
          for (let index = 0; index < data.result.length; index++) {
            const element = data.result[index];
            let userObject = {
              role: "user",
              content: element.user_content,
            };
            let botObject = {
              role: "assistant",
              content: element.bot_content,
            };
            newArray.push(userObject);
            newArray.push(botObject);
          }
          dispatch(chatListState(newArray));
        } else dispatch(chatListState([]));
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(chatListState([]));
      });
  }

  const onSend = () => {
    let newArray = [...chatsList];
    newArray.push({ role: "user", content: text });
    dispatch(chatListState(newArray));
    setText("");

    const user = JSON.parse(localStorage.getItem(USER_LOGIN_DATA));
    const catId = localStorage.getItem(CATEGORY_ID);

    let object = {
      // userContent: message,
      title: text,
      category_id:
        selectedCategoryId || catId || (chatsList.length > 0 ? chatsList[0]?.category_id : null),
      user_id: userData?._id || user?._id,
      chat_head_id: selectedChatId ? selectedChatId : null,
      messages: newArray,
    };

    navigate(routes.chat_bot_main_chat, {
      state: { chat_head_id: selectedChatId },
    });
    setIsLoading(true);
    sendMessage(object)
      .then(({ data }) => {
        if (data.error_code == ErrorCodes.success) {
          // setChatHeadID((chatHeadID = data.result.chat_head._id));
          dispatch(selectedChatIdState(data.result.chat_head._id));
          // setLoopDone(false);
          setIsLoading(false);
          let newText = data.result.result;
          newArray.push({ role: "assistant", content: newText });
          dispatch(chatListState(newArray));
          dispatch(chatHeadStatus(data.result.chat_head));
          setText("");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handlePasteFromDiv = (divName) => {
    const divText = document.getElementById(divName).innerText;
    setText(divText);
  };

  return (
    <div className="w-full h-max pt-5 px-2">
      <div className="w-full h-max mb-5 overflow-y-scroll no-scrollbar rounded-xl bg-white">
        <ChatBotRightHomeCategories handlePasteFromDiv={handlePasteFromDiv} />
      </div>
      <form className="flex my-2  gap-2">
        <input
          value={text}
          onChange={handleInputChange}
          className="main_chatBot_input shadow-xl"
          type="text"
          placeholder="Ask or Search anything"
        />
        <button
          onClick={onSend}
          className={`${text ? "bg-white" : "bg-slate-200"} py-1 px-5 rounded shadow-xl`}
          style={{
            cursor: text ? "" : "default",
            border: text ? "1px solid skyblue" : "",
          }}
        >
          <img src={buttonIcon} alt="" />
        </button>
      </form>
    </div>
  );
}

export default ChatbotRight_Home;

const ChatBotRightHomeCategories = ({ handlePasteFromDiv }) => {
  return (
    <div className="md:mt-2 py-8 flex flex-col justify-center" id="communication">
      <div className="w-2/3 my-4 m-auto text-center ">
        <h1 className="text-lg chatBot_main_heading md:text-2xl font-semibold md:pb-1">
          Welcome to Orbi powered by Orbix 💬
        </h1>
        <p className="lg:px-40 py-1 chatBot_main_heading_text text-gray-400">
          Get Started by writing a task and Orbix Chat can do the rest. Not sure where to start?
          Check out the Prompt Library for inspiration.
        </p>
      </div>
      <div className="md:my-6 py-4 w-4/5 grid grid-cols-12 gap-3 justify-center m-auto">
        <div className="cursor-pointer flex bg-gray-200  md:h-16 rounded-lg py-1 md:mr-10  col-span-12 md:col-span-6 items-center ">
          <img className="w-8 relative -left-4" src={chatIcon_1} alt="" />
          <div onClick={() => handlePasteFromDiv("sourceDiv1")}>
            <h4 className="chatBot_main_group_heading">Underwriting Asistant</h4>
            <p id="sourceDiv1" className="text-gray-400 chatBot_main_group_heading_text pr-2">
              Need help with underwriting? Provide details about the client's medical history,
              medications, and other relevant information, and let Orbi handle the rest.
            </p>
          </div>
        </div>
        <div className="cursor-pointer flex bg-gray-200  md:h-16  rounded-lg py-1 md:mr-10  col-span-12 md:col-span-6  items-center">
          <img className="w-8 relative -left-4" src={chatIcon_2} alt="" />
          <div onClick={() => handlePasteFromDiv("sourceDiv2")}>
            <h4 className="chatBot_main_group_heading">Product Information</h4>
            <p id="sourceDiv2" className="text-gray-400 chatBot_main_group_heading_text pr-2">
              Unsure which carrier is the best fit for your client? Share the client's needs and
              preferences, and Orbi will recommend the most suitable insurance providers.
            </p>
          </div>
        </div>
        <div className="cursor-pointer flex bg-gray-200  md:h-16  rounded-lg py-1 md:mr-10  col-span-12 md:col-span-6  items-center">
          <img className="w-8 relative -left-4" src={chatIcon_2} alt="" />
          <div onClick={() => handlePasteFromDiv("sourceDiv3")}>
            <h4 className="chatBot_main_group_heading">Carrier Recommendation</h4>
            <p id="sourceDiv3" className="text-gray-400 chatBot_main_group_heading_text pr-2">
              Looking for information on a specific insurance product? Ask Orbi about different
              policies, riders, and coverage options to find the perfect match for your client.
            </p>
          </div>
        </div>
        <div className="cursor-pointer  flex bg-gray-200  md:h-16  rounded-lg py-1 md:mr-10  col-span-12 md:col-span-6  items-center">
          <img className="w-8 relative -left-4" src={chatIcon_1} alt="" />
          <div onClick={() => handlePasteFromDiv("sourceDiv4")}>
            <h4 className="chatBot_main_group_heading">Sales Strategy</h4>
            <p id="sourceDiv4" className="text-gray-400 chatBot_main_group_heading_text pr-2">
              Want to improve your sales approach? Describe your client's financial and health
              detail, and Orbi will provide tailored strategies and tips to help you close the deal.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
