import { USER_LOGIN, USER_LOGOUT } from "../ActionType";

const INITIAL_STATE = {
    userData: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return { ...state, userData: action.payload };
        case USER_LOGOUT:
            return { ...state, userData: null };
        default:
            return state;
    }
};
