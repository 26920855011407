import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../utils/routes";
import { LuDot } from "react-icons/lu";
import { getEmployeeDashboardListCall } from "../../hooks/employee";
import Loader from "../../components/loading-spinner/Loader";
import { useSelector } from "react-redux";

function SecondWelcome_Employee() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [hide, setHide] = useState("");

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (location.pathname === routes.dashboard_employee_list) {
      setHide("hidden");
    } else {
      setHide("");
    }
  }, [location.pathname]);

  const handleNavigate = () => {
    navigate(routes.dashboard_employee_list);
  };

  return (
    <div ref={ref} className="col-span-12 w-screen flex justify-center items-center">
      <div className="w-11/12 pt-14 pl-14">
        <div className="w-full h-auto md:px-7 py-3">
          <div className="flex justify-between w-full p-5">
            <div className="employee_heading">
              <h1>Add Employee</h1>
              <p className="text-sm text_navigate font-normal flex justify-center items-center">
                Dashboard <LuDot className="text-xl text-gray-500" /> Employee
              </p>
            </div>
            <div>
              <button
                onClick={handleNavigate}
                className={`${hide} add_button w-28 md:text-sm py-2 text-center font-semibold rounded-3xl bg-blue-500 text-white`}
              >
                + New User
              </button>
            </div>
          </div>
          <div className="shadow-xl bg-white w-full min-h-[87vh] h-auto rounded-2xl flex flex-col lg:justify-center items-center">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondWelcome_Employee;
