import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../utils/routes";
import "./profile_style.css";
import OrbixNavbar from "../orbixNavbar/OrbixNavbar";
import { BiArrowBack } from "react-icons/bi";

function Profile() {
  const ref = useRef(null);
  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Update title and text based on the current path
    if (location.pathname === routes.profile) {
      setTitle("Edit Profile");
      setText(
        "Please edit your information here, some information is not editable."
      );
    } else if (location.pathname === routes.profile_security) {
      setTitle("Change Password");
      setText("Leave blank if you don’t want to change your password.");
    }
  }, [location.pathname]);

  const handleItemClick = (path) => {
    navigate(path);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div ref={ref}>
      <OrbixNavbar />
      <div className="profile_main w-full px-7 pt-4 pb-10">
        <div className="w-full min-h-screen h-auto bg-white rounded-lg shadow-2xl">
          <div className="flex justify-center px-7 pb-3 py-7 md:flex-row flex-col-reverse">
            <div>
              <div className="flex items-center gap-2">
                <BiArrowBack
                  className="cursor-pointer"
                  onClick={handleBackClick}
                />
                <h1 className="edit_heading">{title}</h1>
              </div>
              <p className="pl-6 edit_heading_text pt-1">{text}</p>
            </div>
            <div className="Profile_Navbar w-52 h-10 rounded-full flex justify-center items-center gap-1 px-1">
              <div
                onClick={() => handleItemClick(routes.profile)}
                className="profile_navbar_text cursor-pointer w-24 h-9 rounded-full text-center content-center font-semibold"
                style={{
                  backgroundColor:
                    location.pathname === routes.profile ? "#328BDE" : "",
                  color: location.pathname === routes.profile ? "white" : "",
                }}
              >
                Profile
              </div>
              <div
                onClick={() => handleItemClick(routes.profile_security)}
                className="profile_navbar_text cursor-pointer w-24 h-9 rounded-full text-center content-center font-semibold"
                style={{
                  backgroundColor:
                    location.pathname === routes.profile_security
                      ? "#328BDE"
                      : "",
                  color:
                    location.pathname === routes.profile_security
                      ? "white"
                      : "",
                }}
              >
                Security
              </div>
            </div>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
