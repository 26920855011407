import React, { useEffect, useRef, useState } from "react";
import "./newPass.css";
import logo from "../../assets/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { forgotPasswordResetApiCall } from "../../hooks/userManagment";
import { ErrorCodes } from "../../utils/enums";
import Loader from "../../components/loading-spinner/Loader";

export default function NewPassword() {
  const navigate = useNavigate();
  const stateData = useLocation();
  const ref = useRef(null);

  const [PassErr, setPassErr] = useState(false);
  const [isSecure, setIsSecure] = useState(false);
  const [isSecureConfirm, setIsSecureConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email] = useState(localStorage.getItem("forgetPasswordEmail"));
  const [state, setState] = useState({
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "password") setPassErr(false);

    setState((prev) => ({ ...prev, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (state.password !== state.confirm_password) setPassErr(true);
    else {
      updatePassword();
      // navigate(routes.login);
    }
  }

  function updatePassword() {
    let object = {
      email: email,
      code: stateData.state,
      password: state.password,
    };
    setIsLoading(true);
    forgotPasswordResetApiCall(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCodes.success) {
          localStorage.removeItem("forgetPasswordEmail");
          alert("Password update succesfully");
          navigate(routes.login);
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  return (
    <div ref={ref} className="signup-container">
      <div className="login-left login">
        <div className="left-content">
          <img src={logo} alt="Orbix Bot" className="robot-image" />
          <h1 className="text-black">Here you go to rejoin the best AI Insurance Agent</h1>
          <p className="text-black">
            Secure your account by updating your password. Enter your new password below and confirm
            it to ensure your information stays protected. If you encounter any issues, our support
            team is always ready to assist you.
          </p>
        </div>
      </div>
      <div className="signup-right">
        <form className="signup-form" onSubmit={handleSubmit}>
          <h2>Update Your Password</h2>

          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="password">Password</label>
            <input
              style={{ border: PassErr ? "1px solid red" : "" }}
              type={isSecure ? "text" : "password"}
              id="password"
              name="password"
              value={state.password}
              onChange={handleChange}
              placeholder="Enter your Password"
              required
              disabled={isLoading}
            />
            <span className="eye_icon" onClick={() => setIsSecure(!isSecure)}>
              {isSecure ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
            </span>
          </div>

          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="password">Confirm Password</label>
            <input
              style={{ border: PassErr ? "1px solid red" : "" }}
              type={isSecureConfirm ? "text" : "password"}
              id="password"
              name="confirm_password"
              value={state.confirm_password}
              onChange={handleChange}
              placeholder="Re enter your Password"
              required
            />
            {PassErr && <span className="new_pass_input_error">fields doesn't match*</span>}
            <span className="eye_icon" onClick={() => setIsSecureConfirm(!isSecureConfirm)}>
              {isSecureConfirm ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
            </span>
          </div>
          <button type="submit" className="btn-signup" style={{ marginTop: "40px" }}>
            Update Password
          </button>
        </form>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}
