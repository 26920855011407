import React, { useEffect, useRef, useState } from "react";
import "../../components/Dashboard/Faq_style.css";
import Faq_questions from "../../components/Dashboard/Faq_questions";
import { getFaqs } from "../../hooks/dashboard";
import { ErrorCodes } from "../../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";

function SecondWelcome_Faq() {
  const ref = useRef(null);
  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);
  const Faq_block = [
    {
      question: "How to Find Books ",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
    {
      question: "How to Find Books",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
    {
      question: "How to Find Books",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
    {
      question: "How to Find Books",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
    {
      question: "How to Find Books",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
    {
      question: "How to Find Books",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
    {
      question: "How to Find Books",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
    {
      question: "How to Find Books",
      answer:
        "We can find book from online store Lorem ipsum is a placeholder text commonly.We can find book from online store Lorem ipsum is a placeholder text commonly.We can find book from online store Lorem ipsum is a placeholder text commonly",
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

  function fetchFaqs() {
    setIsLoading(true);
    getFaqs()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code === ErrorCodes.success) {
          setData(data.result);
        } else if (data.error_code === ErrorCodes.failed) {
          // toast.warn("No record found");
        } else {
          toast.warn("Oops! Some error occured");
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Network Error");
      });
  }

  return (
    <>
      <ToastContainer
        autoClose={1000}
        hideProgressBar={true}
        position="bottom-right"
      />
      <div
        ref={ref}
        className=" col-span-12 w-screen content-center text-center flex justify-center items-center "
      >
        <div className="w-11/12 pt-14 pl-14">
          <div className="w-full h-auto md:px-7 py-3">
            <div className="shadow-xl bg-white w-full min-h-[85vh] h-auto rounded-2xl flex flex-col items-center ">
              <div className="px-3 md:px-0 w-70 md:w-1/2 flex flex-col justify-center items-center py-10">
                <h1 className="faq_main_heading">FAQ</h1>
                <p className="faq_main_heading_text pt-4 faq_light_text text-sm">
                  Discover how Orbix simplifies insurance! From underwriting
                  guidance to product details and sales tips, find quick answers
                  to your insurance questions here.
                </p>
              </div>

              <div className="w-3/4 grid grid-cols-12 items-start justify-start gap-4 pb-5 overflow-y-scroll no-scrollbar">
                {isLoading ? (
                  <div className="col-span-12 flex justify-center items-center ">
                    <LoadingSpinner />
                  </div>
                ) : data.length === 0 ? (
                 <div className="col-span-12 flex justify-center items-center text-3xl font-semibold underline py-5">
                  There is no FAQs
                 </div>
                ) : (
                  data.map((items) => (
                    <Faq_questions
                      answer={items.answer}
                      question={items.question}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondWelcome_Faq;
