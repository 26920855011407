import React, { useRef, useState } from "react";
import { noImage } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userUpdateApiCall } from "../../hooks/userManagment";
import { loginAction } from "../../redux/AppAction";
import { BASE_URL } from "../../utils/constants";

function Profile_Edit() {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.user);

  // console.log("userData", userData);
  const [selectedImage, setSelectedImage] = useState(
    userData?.image
      ? `${BASE_URL}${userData.image}`
      : noImage
  );

  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    _id: userData._id,
    name: userData?.name || "",
    email: userData?.email || "",
    phone: userData?.phone || "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear field-specific errors
    });
  };

  // Handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("Image size must not exceed 2MB");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // Validate form inputs
  const validate = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }

    const phoneRegex = /^\d{11}$/; // Matches exactly 10 digits
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    setIsLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("_id", formData._id);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);

    if (fileInputRef.current.files[0]) {
      formDataToSend.append("image", fileInputRef.current.files[0]);
    }
    console.log("formDataToSend", formDataToSend);
    userUpdateApiCall(formDataToSend)
      .then(({ data }) => {
        if (data.error_code == 0) {
          toast.success("Profile updated successfully");
          debugger;
          if (userData) {
            const newUserData = {
              ...userData,
              ...data.result,
            };

            dispatch(loginAction(newUserData));
          }

          console.log("userData", userData);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("An error occurred while updating the profile");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full h-full p-7">
      <div className="w-full h-full flex flex-col justify-start items-start gap-5">
        <div className="flex justify-center items-center gap-5">
          <div
            className="image_size w-28 h-28 rounded-full"
            onClick={handleImageClick}
          >
            <img
              className="image_size w-28 h-28 rounded-full"
              src={selectedImage || noImage}
              alt="Profile"
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: "none" }}
              accept="image/*"
              name="image_"
            />
          </div>

          <div>
            <div className="upload_text">Upload Photo</div>
            <div className="upload_detail">300x300 and max 2MB</div>
          </div>
        </div>
      </div>

      <div className="w-3/5 pt-5">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-5 pb-5">
            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Name:
                <input
                  className={`profile_input ${
                    errors.name ? "border-red-500" : ""
                  }`}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <span className="text-red-500 text-xs">{errors.name}</span>
                )}
              </label>
            </div>

            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Email address:
                <input
                  className={`profile_input ${
                    errors.email ? "border-red-500" : ""
                  }`}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                />
                {errors.email && (
                  <span className="text-red-500 text-xs">{errors.email}</span>
                )}
              </label>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-5 items-end">
            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Phone Number:
                <input
                  className={`profile_input ${
                    errors.phone ? "border-red-500" : ""
                  }`}
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && (
                  <span className="text-red-500 text-xs">{errors.phone}</span>
                )}
              </label>
            </div>
            <button
              className="col-span-12 md:col-span-6 py-3 profile_button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile_Edit;
