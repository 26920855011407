export const routes = {
  login: "/login",
  signup: "/signup",
  forget_password: "/forget-password",
  otp: "/otp",
  set_new_password: "/set-new-password",
  help: "/help",
  privacy_policy: "/privacy-policy",
  business_role: "/business-role",
  hear_from: "/hear-from",
  welcome: "/welcome",
  subscription: "/subscription",
  dashboard: "/dashboard",
  dashboard_billing: "/dashboard/billing",
  dashboard_affiliate: "/dashboard/affiliate",
  dashboard_employee: "/dashboard/employee",
  dashboard_employee_list: "/dashboard/employee/employee_list",
  dashboard_setting: "/dashboard/setting",
  dashboard_faq: "/dashboard/faq",
  chat_bot_main: "/chat-bot",
  chat: "/chat",
  profile: "/profile",
  profile_security: "/profile/security",
  chat_bot_main_chat: "/chat-bot/chat",
  plans: "/plans",
  contact_us: "/contactUs",
  dashboard_plans: "/dashboard/plans",
  features: "/features",
  referral: "/referral",
  referral_plan: "/referral/plan",
};
