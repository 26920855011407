import React, { useEffect } from "react";
import PlansNew from "./home/PlansNew";
import backIcon from "../assets/BackIcon.png";
import { USER_LOGIN_DATA } from "../utils/constants";
import { useDispatch } from "react-redux";
import { logout } from "../redux/AppAction";
import { useNavigate } from "react-router-dom";
import { routes } from "../utils/routes";

export default function PlanScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={`col-span-12 w-screen ${
        window.location.pathname == "/dashboard/plans" ? "ml-11" : "ml-0"
      } flex justify-center items-center relative`}
    >
      <PlansNew />
      {window.location.pathname == "/subscription" && (
        <div
          onClick={() => {
            localStorage.removeItem(USER_LOGIN_DATA);
            dispatch(logout());
            navigate("/");
          }}
          // className="hover:bg-[#e2f2ff] h-10 rounded cursor-pointer flex flex-col justify-center items-center exitButton py-2 gap-1"
          className="absolute top-8 right-8 cursor-pointer"
        >
          <img src={backIcon} className="w-6 cursor-pointer" alt="Back" />
        </div>
      )}
    </div>
  );
}
