import moment from "moment/moment";
import React from "react";

function RightChat({ content = "", time }) {
  return (
    // <div className="md:w-3/5 ml-auto mr-0  my-2 ">
    //   <div className="rounded-xl p-2 flex gap-2 bg-gray-300">
    //     <div className="chatBot_communi_chat">{content}</div>
    //     <div className="flex flex-col justify-between">
    //       <p className="chatBot_communi_chat_date">
    //         {moment(time).format("MM-DD")}
    //       </p>
    //       <img className="w-24" alt="" />
    //     </div>
    //   </div>

    <div className="  my-2 ">
      <div className=" flex gap-2">
        <div className="flex ml-auto mr-0 rounded-xl p-2 bg-gray-300 ">
          <div className="chatBot_communi_chat ">{content}</div>
          <div className="flex flex-col justify-between items-end chatBot_communi_detail">
            <p className="chatBot_communi_chat_date h-full content-end">
              {moment(time).format("MMM-DD")}
            </p>
            <img className='w-24' alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightChat;
