import { axiosInstance } from "../axios-client/axiosClient";

export const getEmployeeDashboardListCall = async (user_plan_id) => {
    let params = {}
    if (user_plan_id) params.user_plan_id = user_plan_id
    const api = axiosInstance();
    return await api.get("/api/employee/dashboard-list", {
        params
    });
};

export const employeeDeleteListCall = async (data) => {
    const api = axiosInstance();
    return await api.post("/api/employee/delete-list", data);
};

export const employeeAddCall = async (data) => {
    const api = axiosInstance();
    return await api.post("/api/employee/add", data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

export const employeeEditCall = async (data) => {
    const api = axiosInstance();
    return await api.post("/api/employee/edit", data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

export const employeeGetEmployeeCall = async (id) => {
    const api = axiosInstance();
    let params = {}
    if (id) params.id = id
    return await api.get("/api/employee/get-employee", {
        params
    });
};