import "./Loading.css";

export default function Loader({ style }) {
  return (
    <div
      className="model__container__bigger"
      style={{ justifyContent: "center", zIndex: "9999999" }}
    >
      <div className="loading-spinner-bigger"></div>
    </div>
  );
}
