import React from "react";
import Footer from "../home/Footer";

export default function Help() {
    return (
        <>
        
        <div className="max-w-[1150px] mx-auto p-6 bg-white rounded-md my-10">
    <h1 className="text-3xl font-bold text-[#3995EB] mb-4 text-center">Help Center</h1>
    <p className="text-sm text-gray-500 mb-6 text-center"><em>Last Updated: 2024-10-31</em></p>

    <h2 className="text-2xl font-semibold text-[#3995EB] mb-4">How Can We Help You?</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
      Welcome to the Orbi Help Center! Here, you can find answers to common questions and guidance on using our platform effectively. Whether you're looking for support 
      with life insurance applications or have questions about our integration features, we've got you covered. Please explore the sections below for assistance.
    </p>

    <h2 className="text-2xl font-semibold text-[#3995EB] mb-4">Frequently Asked Questions (FAQs)</h2>
    
    <div className="space-y-4 mb-6">
      <div>
        <h3 className="text-lg font-semibold text-[#3995EB]">1. What is Orbi?</h3>
        <p className="text-gray-700 leading-relaxed">
          Orbi is a comprehensive resource for life insurance and annuity companies, serving as a mentor, coach, encyclopedia, and underwriter. It provides immediate 
          answers and guidance on products and carriers, helping users expedite their application processes and reduce frustration.
        </p>
      </div>
      <div>
        <h3 className="text-lg font-semibold text-[#3995EB]">2. How do I use Orbix integration features?</h3>
        <p className="text-gray-700 leading-relaxed">
          To use Orbix, simply connect it to your existing systems. Our smart bot will assist you in streamlining your workflow, allowing for improved efficiency and 
          enhanced data management.
        </p>
      </div>
      <div>
        <h3 className="text-lg font-semibold text-[#3995EB]">3. How can I contact customer support?</h3>
        <p className="text-gray-700 leading-relaxed">
          If you have any questions or need assistance, feel free to reach out to our customer support team at orbix. We're here to help!
        </p>
      </div>
      <div>
        <h3 className="text-lg font-semibold text-[#3995EB]">4. What information does Orbi collect?</h3>
        <p className="text-gray-700 leading-relaxed">
          Orbi collects personal information such as your name, email, phone number, and usage data to provide you with personalized support and resources. For more 
          details, please refer to our Privacy Policy.
        </p>
      </div>
    </div>

    <h2 className="text-2xl font-semibold text-[#3995EB] mb-4">General Guidance</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
      If you're new to Orbi, we recommend starting with our guided tutorials available on the platform. These resources will help you navigate through our various features, 
      ensuring you make the most of your experience. Additionally, feel free to explore our blog for tips and insights related to life insurance and annuities.
    </p>

    <h2 className="text-2xl font-semibold text-[#3995EB] mb-4">Contact Us</h2>
    <p className="text-gray-700 leading-relaxed mb-4">
      If you still need help after browsing our FAQs and guidance, please don't hesitate to contact us. Our dedicated support team is available to assist you with any 
      inquiries you may have.
    </p>
    <p className="text-gray-700 leading-relaxed">
      Email: <a href="mailto:support@orbi.com" className="text-blue-500">support@orbi.com</a><br />
      Phone: xxxxxxxxx
    </p>
  </div>
  <Footer />
  </>
    );
}
