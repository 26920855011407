import "./referralPlan.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../utils/routes";
import Loader from "../../components/loading-spinner/Loader";
import { FaCheckCircle } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { isValidEmail } from "../../utils/text-utils";
import { userValidateEmail } from "../../hooks/userManagment";
import { IoCloseCircle } from "react-icons/io5";
import { getClientSecretCall } from "../../hooks/plan";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { STRIPE_PUBLISH_KEY } from "../../utils/constants";

const ReferralPlanPage = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

  const [clientSecretData, setClientSecretData] = useState(null);
  let [planData, setPlanData] = useState(location.state);
  let [userData, setUserData] = useState(null);
  let [addressData, setAddressData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function getClientSecret(plan_id) {
    if (plan_id && planData.referralCode) {
      let object = {
        user_email: userData.email,
        plan_id: plan_id,
        referral_code: planData.referralCode,
      };

      setIsLoading(true);
      getClientSecretCall(object)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == 0) {
            setClientSecretData(data.result);
          } else {
            setClientSecretData(null);
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  }

  function getAmountAfterDiscount(price) {
    let discountedPrice =
      (parseInt(price) / 100) * parseFloat(planData.referralDiscountData.referee_discount);
    discountedPrice = price - discountedPrice;
    return discountedPrice.toFixed(2);
  }

  return (
    <div
      ref={ref}
      className={`col-span-12 w-screen flex justify-center items-center ${
        stripePromise && clientSecretData && "overflow-hidden"
      }`}
    >
      <div className="w-11/12 pt-14 pl-14">
        <div className="w-full h-auto xxs:px-7 py-3">
          <div className="shadow-xl bg-white w-full min-h-[85vh] h-auto rounded-2xl flex flex-col items-center">
            <div className="w-full p-3 md:p-6">
              <div className="flex md:flex-row flex-col gap-2 items-center justify-center">
                <div className="w-3/4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="text-lg font-semibold">{`${planData?.plan_name} (${planData?.tag_line})`}</div>
                    <div className="flex flex-row gap-2">
                      <div className="text-base text-slate-400 font-semibold line-through">
                        ${planData?.plan_price}
                      </div>
                      <div className="text-base font-semibold">
                        ${getAmountAfterDiscount(planData.plan_price ? planData.plan_price : 0)}
                      </div>
                    </div>
                  </div>

                  {planData.features
                    ? planData.features.map((item, index) => (
                        <div className="flex flex-row justify-between items-center">
                          <div className="flex flex-row gap-2 items-center w-4/5">
                            <TiTick color="green" size={25} />
                            <div className="text-lg font-normal text-slate-700">{item}</div>
                          </div>
                        </div>
                      ))
                    : null}

                  {/* <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 items-center">
                      <TiTick color="green" size={25} />
                      <div className="text-lg font-normal text-slate-700">
                        Domain WHOIS Privacy Protection
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="text-base font-semibold">$0</div>
                    </div>
                  </div> */}

                  <hr className="w-full flex border border-slate-300 rounded my-5" />

                  {stripePromise && (
                    <Elements
                      stripe={stripePromise}
                      // options={{ clientSecret: clientSecretData.client_secret }}
                    >
                      <AddressElementForm
                        callBack={(user_data, value) => {
                          setUserData((userData = user_data));
                          setAddressData((addressData = value));
                          getClientSecret(planData.plan_id);
                        }}
                      />
                    </Elements>
                  )}

                  {stripePromise && clientSecretData && (
                    <div className="card__main__container">
                      <div className="card_container no-scrollbar">
                        <div className="sub_card_container">
                          <Elements
                            stripe={stripePromise}
                            options={{ clientSecret: clientSecretData.client_secret }}
                          >
                            <CheckoutForm
                              clientSecretData={clientSecretData}
                              addressData={addressData}
                              callBack={() => {
                                setClientSecretData(null);
                                alert("Subscribed to plan successfully. Please login to continue.");
                                navigate(routes.login);
                              }}
                              onClose={() => setClientSecretData(null)}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <Loader /> : null}
    </div>
  );
};

function AddressElementForm({ callBack = () => {} }) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSecure, setIsSecure] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!stripe || !elements) return;

    let addressElement = elements.getElement(AddressElement);
    const { complete, value } = await addressElement.getValue();

    if (!isValidEmail(email)) {
      alert("Plese provide email address");
    } else if (!password) {
      alert("Plese provide password");
    } else if (complete) {
      let object = {
        email: email,
        name: `${value.name}`,
        password: password,
      };

      setIsLoading(true);
      userValidateEmail(object)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == 0) {
            let user_object = {
              _id: data.result,
              email: email,
            };
            callBack(user_object, value);
          } else alert(data.message);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      <div className="email__input">
        <label htmlFor="email">Email Address</label>
        <input
          placeholder="Enter your email"
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div className="email__input">
        <label htmlFor="password">Password</label>
        <input
          type={isSecure ? "text" : "password"}
          placeholder="Enter your password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <span className="eyeee_icon" onClick={() => setIsSecure(!isSecure)}>
          {isSecure ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
        </span>
      </div>

      <AddressElement options={{ mode: "billing" }} />

      <div className="flex justify-center items-center mt-6">
        <button
          type="submit"
          className="w-auto rounded-md py-2 px-5 bg-blue-500 text-white"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? <LoadingSpinner /> : "Pay Now"}
        </button>
      </div>
    </>
  );
}

function CheckoutForm({
  clientSecretData = null,
  addressData,
  callBack = () => {},
  onClose = () => {},
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${routes.login}`,
        payment_method_data: { billing_details: addressData },
      },
      redirect: "if_required",
    });

    console.log("error", error);
    if (error) {
      setMessage(error?.message);
      setIsLoading(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setIsLoading(false);
      callBack();
    } else {
      setIsLoading(false);
      setMessage("unexpected state.");
    }
  }
  return (
    <form className="flex flex-col p-5 relative" onSubmit={handleSubmit}>
      <IoCloseCircle
        size={27}
        style={{ position: "absolute", top: "-15px", right: "-15px", cursor: "pointer" }}
        onClick={onClose}
      />
      <PaymentElement />

      {clientSecretData.trial_ends_on ? (
        <span className="text-gray-500 font-medium text-xs my-3">{`You will be charged $${clientSecretData.amount_will_charged} after your trial period ends`}</span>
      ) : null}
      <button type="submit" className="pay_btn" disabled={isLoading}>
        {isLoading ? <LoadingSpinner /> : `Pay Now $${clientSecretData.amount_pay_now}`}
      </button>
      {message && <span className=" text-red-500 font-medium text-sm my-3">{message}</span>}
    </form>
  );
}

export default ReferralPlanPage;
