import React, { useState } from "react";
import "./forgetPass.css";
import logo from "../../assets/logo.svg";
import forgetPasswordHalfImg from "../../assets/forgetPasswordHalfImg.svg";
import { useNavigate } from "react-router-dom";
import { GoVerified } from "react-icons/go";
import { routes } from "../../utils/routes";
import { forgotPasswordResetApiCall, generateCode } from "../../hooks/userManagment";
import { ErrorCodes } from "../../utils/enums";
import Loader from "../../components/loading-spinner/Loader";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/AppAction";
import { USER_LOGIN_DATA } from "../../utils/constants";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailErr, setEmailErr] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    const { value } = e.target;
    setEmailErr(false);
    setEmail(value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!email) setEmailErr(true);
    else {
      forgetPassword();
    }
  }

  function forgetPassword() {
    let object = {
      email: email,
    };
    setIsLoading(true);
    dispatch(loginAction(null));
    localStorage.removeItem(USER_LOGIN_DATA);
    generateCode(object)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCodes.success) {
          localStorage.setItem("forgetPasswordEmail", email);
          navigate(routes.otp);
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  return (
    <div className="signup-container  flex flex-col md:flex-row ">
      <div class="left-section w-full md:w-1/2 ">
        <div class="top-div">
          <img
            style={{ objectFit: "contain", width: "100%" }}
            src={forgetPasswordHalfImg}
            alt="Robot Image"
            class="robot-image"
          />
        </div>
        <div class="bottom-div loginWidth">
          <img src={logo} alt="Orbix Logo" class="logo" />
          <h1 className="text-2xl md:text-3xl">Oops, did you forget your password?</h1>
          <p class="text">
            No worries! It happens to the best of us. Just enter your email address below, and we'll
            send you instructions to reset your password. If you need further assistance, our
            friendly support team is here to help you get back on track.
          </p>
        </div>
      </div>
      <div className="signup-right">
        <form
          className="signup-form"
          style={{ position: "relative", marginTop: "20px" }}
          onSubmit={handleSubmit}
        >
          <span className="forget_pass_icon">
            <GoVerified size={20} />
          </span>
          <h2 style={{ marginTop: "20px" }}>Verify Your Identity</h2>
          <p>Enter your email address to get OTP to reset password.</p>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              style={{
                border: emailErr ? "1px solid red" : "",
                borderRadius: "10px",
                padding: "1rem .75rem",
              }}
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              placeholder="Enter your Email"
              required
            />
          </div>

          <button
            type="submit"
            className="btn-signup"
            disabled={isLoading}
            style={{ marginTop: "2rem" }}
          >
            Continue
          </button>
          <p className="login_signup_text" style={{ alignSelf: "flex-start", marginTop: "2rem" }}>
            <span onClick={() => navigate(routes.signup)}>Create a new account?</span>
          </p>
        </form>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}
