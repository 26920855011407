import React, { useState } from "react";
import bot from "../assets/contact_robo.png";
import { contactUsCall } from "../hooks/userManagment";
// import './ContactUs.css'
function ContactUs() {
  const [state, setState] = useState({
    // firstname: "",
    // lastname: "",
    // companyName: "",
    email: "",
    // mobile: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  function isViewValid() {
    if (!state.email) alert("Please enter your email.");
    else if (!state.subject) alert("Please enter subject.");
    else if (!state.message) alert("Please enter your query.");
    else return true;
    return false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isViewValid()) {
      let object = {
        email: state.email,
        subject: state.subject,
        notes: state.message,
      };
      setIsLoading(true);
      contactUsCall(object)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == 0) {
            alert(
              "Than you for contacting us, your query has been submitted. We will let you know shortly."
            );
            setState((prevState) => ({ ...prevState, email: "", subject: "", message: "" }));
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="w-full bg-[#F5F9FF] md:py-5 flex px-2 sm:px-5 h-screen">
      <img className="w-[45%] hidden md:block" src={bot} alt="" />

      <div className="w-full md:w-1/2 m-auto bg-white shadow-xl rounded">
        <form className="form p-[18px] md:p-[30px]" onSubmit={handleSubmit}>
          <p className="title">Get in Touch</p>
          {/* <div className="flex1">
            <label className="w-1/2">
              <input
                className="input1"
                type="text"
                name="firstname"
                placeholder=""
                value={state.firstname}
                onChange={handleChange}
                required
              />
              <span>Name</span>
            </label>

            <label className="w-1/2">
              <input
                className="input1"
                type="text"
                name="companyName"
                placeholder=""
                value={state.companyName}
                onChange={handleChange}
                required
              />
              <span>Company Name</span>
            </label>
          </div> */}
          <div className="flex1">
            <label className="w-1/2">
              <input
                className="input1"
                type="email"
                name="email"
                placeholder=""
                value={state.email}
                onChange={handleChange}
                required
              />
              <span>Email</span>
            </label>
            {/* <label className="w-1/2">
              <input
                className="input1"
                type="email"
                name="mobile"
                placeholder=""
                value={state.mobile}
                onChange={handleChange}
                required
              />
              <span>Mobile (Optional)</span>
            </label> */}
          </div>
          <label className="w-1/2">
            <input
              className="input1"
              type="text"
              name="subject"
              placeholder=""
              value={state.subject}
              onChange={handleChange}
              required
            />
            <span>Subject</span>
          </label>
          <label>
            <textarea
              style={{ resize: "none" }}
              className="input1"
              name="message"
              placeholder=""
              value={state.message}
              onChange={handleChange}
              required
              cols={30}
              rows={4}
            />
            <span>How can we help you</span>
          </label>
          <div className="w-full flex justify-end">
            <button className="submit" disabled={isLoading}>
              {isLoading ? "Processing" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
