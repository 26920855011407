import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdOutlineFileDownload } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import PaginatedTable from "./Pagination";
import { useSelector } from "react-redux";
import Loader from "../../components/loading-spinner/Loader";
import { employeeDeleteListCall, getEmployeeDashboardListCall } from "../../hooks/employee";
import { FaArrowDown } from "react-icons/fa6";
import { noImage } from "../../assets";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BASE_URL } from "../../utils/constants";
import { routes } from "../../utils/routes";
import { useNavigate } from "react-router-dom";

function SecondWelcome_Employee_list() {
  const { userData } = useSelector((state) => state?.user);
  const ref = useRef(null);
  const navigate = useNavigate();

  const [state, setState] = useState({
    employeesList: [],
  });
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isLoading, setIsLoading] = useState("");

  useEffect(() => {
    ref.current.scrollIntoView();
    if (userData) getEmployees();
  }, [userData]);

  function getEmployees() {
    if (userData.subscribed_plan) {
      setIsLoading(true);
      getEmployeeDashboardListCall(userData.subscribed_plan._id)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == 0) {
            for (let index = 0; index < data.result.length; index++) {
              const element = data.result[index];
              element.is_checked = false;
            }
            setState((prevState) => ({ ...prevState, employeesList: data.result }));
          } else setState((prevState) => ({ ...prevState, employeesList: [] }));
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  }

  function deleteEmployees() {
    let deleteArray = [];
    for (let index = 0; index < state.employeesList.length; index++) {
      const element = state.employeesList[index];
      if (element.is_checked) {
        let object = {
          _id: element._id,
        };
        deleteArray.push(object);
      }
    }
    if (deleteArray.length > 0) {
      setIsLoading(true);
      employeeDeleteListCall(deleteArray)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == 0) {
            getEmployees();
          } else alert(data.message);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  }

  function EmployeeEntry({ item, index }) {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    return (
      <tr key={index} className="table_body_hover relative">
        <td className="table_body">
          <input
            className="employee_check_box cursor-pointer"
            type="checkbox"
            checked={item.is_checked}
            onClick={() => {
              let newData = [...state.employeesList];
              newData[index].is_checked = !newData[index].is_checked;
              setState((prevState) => ({ ...prevState, employeesList: newData }));
            }}
          />
        </td>
        <td className="table_body text-start flex items-center">
          <img
            className="w-6 h-6 object-cover md:w-10 md:h-10 rounded-full"
            src={item.image_path ? BASE_URL.concat(item.image_path) : noImage}
            alt="Profile"
          />
          <h1 className="font-bold text-black ml-2">{item.full_name}</h1>
        </td>
        <td className="table_body">DB Pending</td>
        <td className="table_body">DB Pending</td>
        <td className="table_body">{item.is_verified ? "Yes" : "No"}</td>
        <td className="table_body">{item.status ? "Active" : "Not Active"}</td>
        <td
          className="table_body"
          onMouseEnter={() => setIsMenuVisible(true)}
          onMouseLeave={() => setIsMenuVisible(false)}
        >
          <BiDotsVerticalRounded className="employee_icon cursor-pointer" />
          {isMenuVisible && (
            <div className="absolute z-[99999] right-14 mt-1 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
              <ul className="list-none p-0 m-0">
                <li
                  className="px-4 py-2 hover:bg-gray-200"
                  onClick={() => {
                    navigate(routes.dashboard_employee_list, { state: item });
                  }}
                >
                  Edit
                </li>
              </ul>
            </div>
          )}
        </td>
      </tr>
    );
  }

  return (
    <div ref={ref} className="w-full p-5">
      <div className="flex items-center w-full gap-3 px-3">
        <div className="employee_Input_Block">
          <FiSearch className="employee_icon" />
          <input className="w-full focus:outline-none" type="text" placeholder="Search..." />
        </div>
        <MdOutlineFileDownload className="employee_icon cursor-pointer" />
        <RiDeleteBinLine
          className="employee_icon cursor-pointer"
          onClick={() => deleteEmployees()}
        />
      </div>
      <div className="h-auto employee_second_container no-scrollbar">
        <div className="employee_table">
          <div className="h-full flex flex-col justify-between">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="table_head table-left">
                    <input
                      className="employee_check_box"
                      type="checkbox"
                      checked={isCheckedAll}
                      onClick={(e) => {
                        let newData = [...state.employeesList];
                        for (let index = 0; index < newData.length; index++) {
                          const element = newData[index];
                          element.is_checked = !isCheckedAll;
                        }
                        setState((prevState) => ({ ...prevState, employeesList: newData }));
                        setIsCheckedAll(!isCheckedAll);
                      }}
                    />
                  </th>
                  <th className="table_head flex items-center gap-2">
                    Name <FaArrowDown />
                  </th>
                  <th className="table_head">Company</th>
                  <th className="table_head">Role</th>
                  <th className="table_head">Verified</th>
                  <th className="table_head">Status</th>
                  <th className="table_head table-right"></th>
                </tr>
              </thead>
              <tbody>
                {state.employeesList.map((item, index) => (
                  <EmployeeEntry item={item} index={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

export default SecondWelcome_Employee_list;
